import React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import HeroUnit from '../components/HeroUnit'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const AboutPage = ({ data }) => {
    const linkedInBgImage = getImage(data.linkedInBg)

    return (
        <Layout>
            <SEOTools
                title='About us'
                description='Siru Mobile was founded in 2011 as a result of observing, that the world wants to be
                                mobile. Standing between mobility and people, were poor customer experiences and a lack
                                of mobile payment options.'
            />

            <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay text-white'>
                <Row className='d-flex align-items-center justify-content-center text-center my-5'>
                    <Col xs='8'>
                        <h1>Passion led us here.</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center my-5 py-5'>
                        <Col xs='10'>
                            <h2>About us</h2>
                            <p>
                                Siru Mobile has been helping people go digital and mobile for over ten years now. The
                                company was founded in 2011 by entrepreneurs who saw that the current financial system
                                leaves many people behind because it's not easy enough to use or accessible.
                            </p>
                            <p>
                                The team has helped more than 400 brands make companies' payment systems work well with
                                smartphones so everyone can enjoy a stress-free life without worrying about cash, cards,
                                or even bank accounts – we all know how hard they are to carry around!
                            </p>
                            <p>
                                Siru Mobile is an innovative tech startup that empowers companies of any size scale and
                                builds simple yet secure point-of-sale solutions.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='dark-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0'>
                    <Container className='py-5 my-5'>
                        <Row className='justify-content-center'>
                            <Col xs='10'>
                                <h2>Part of the community</h2>
                                <p className='spaced-light'>
                                    Siru Mobile has a unique view on the future of technology. We have no interest in
                                    being an outsider, nor do we want to be left behind when change happens. Instead,
                                    our goal is to take advantage of current events and trends by interacting with other
                                    companies within our community while also taking part ourselves for maximum benefit!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={linkedInBgImage}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>

            <section>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center my-5 py-5'>
                        <Col xs='10'>
                            <h2>Follow our journey</h2>
                            <p className='mb-5'>
                                We're on a mission to make the financial services industry more accessible and mobile.
                                Contact us via LinkedIn if you know how to help or empower our way into changing
                                consumer habits for good!
                            </p>
                            <a
                                href='https://www.linkedin.com/company/siru-mobile/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='btn btn-success btn-lg px-5 rounded-0'>
                                Connect on LinkedIn
                            </a>
                        </Col>
                    </Row>
                </Container>
            </section>

            <StyledSection>
                <Container>
                    <Row className='d-flex align-items-center justify-content-center text-center py-5'>
                        <Col xs='10' className='mt-5'>
                            <Row className='d-flex align-items-center py-4'>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Deloitte-logo-black.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/DNA_logo.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/ee-2.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Elisa_logo.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                            <Row className='d-flex align-items-center mb-4 py-2'>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/faia.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/findec-logo-primary.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>

                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/helsinki-fintech.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/lighthouse.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                            <Row className='d-flex align-items-center mb-4 py-2'>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Mastercard-logo.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/O2.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/orange.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Tele2_logo.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                            <Row className='d-flex align-items-center mb-5 py-2'>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Telenor.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Telia_logo.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                                <Col xs='3'>
                                    <StaticImage
                                        src='../images/logos/Vodafone.png'
                                        alt='logo'
                                        placeholder='blurred'
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
        </Layout>
    )
}

const StyledSection = styled.section`
    background-color: var(--light);
`

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "sirumobile_frontpage_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        linkedInBg: file(relativePath: { eq: "sirumobile_aboutus_linkedin.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default AboutPage
